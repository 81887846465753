import React from "react";

import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import config, { localStorageKeys } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { Spin } from "antd";
import Context from "../../context";

export default function LoginScreen(props) {
    const { state, setState } = React.useContext(Context);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [redirect, setRedirect] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [sendVerificationEmail, setSendVerificationEmail] = React.useState(false)

    React.useEffect(() => {
        setState({ ...state, currentScreen: "login" });
        document.title = "Welcome to Skills Stack";
        return () => {
            setIsLoading(false);
            setRedirect(false);
            return null
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const firebaseUser = await signInWithEmailAndPassword(
                getAuth(),
                email,
                password
            );
            if (!firebaseUser.user.emailVerified) {
                throw new Error("Email not verified");
            } else {
                const resp = await axios.post(`${config.baseUrl}users/login`, {
                    email: email,
                    password: password
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*',
                    }
                })

                if (resp.status === 200) {

                    if (resp.data.response.data.user.userType.toLowerCase() != "student") {
                        localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
                            token: resp.data.response.data.token,
                            sessionStartTime: new Date().getTime()
                        }));

                        const currEvents = state.events;
                        currEvents.push({
                            type: "login",
                            time: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
                            user: resp.data.response.data.user._id,
                            username: resp.data.response.data.user.name,
                            success: true,
                            message: "User Logged In"
                        });

                        toast.success("Login Successful", config.toastrOption);
                        setState({
                            ...state,
                            isLoggedIn: true,
                            user: resp.data.response.data.user,
                            isGuest: false,
                            token: resp.data.response.data.token,
                            events: currEvents
                        });
                        setTimeout(() => {
                            setRedirect(true);
                            setIsLoading(false);
                        }, 3000);
                    } else {
                        toast.error("You are not an Admin", config.toastrOption);
                    }
                }
            }

        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            const currEvents = state.events;
            currEvents.push({
                type: "login",
                time: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
                email: email,
                message: error.message || error.response?.data?.message || "Something went wrong",
                success: false
            });

            setState({ ...state, events: currEvents });
            if (error.message === "Firebase: Error (auth/wrong-password).")
                return toast.error("Wrong password", config.toastrOption);
            if (error.message === "Firebase: Error (auth/user-not-found).")
                return toast.error("User not found", config.toastrOption);
            if (error.message === "Email not verified")
                return toast.error("Email has not been verified, please verify then try again !", config.toastrOption);
            if (error.message === "Firebase: Error (auth/too-many-requests).")
                return toast.error("Too many requests for this account, please try again later !", config.toastrOption);
            if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                return toast.error("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", config.toastrOption);
            toast.error(
                error.response?.data?.message || "Something went wrong",
                config.toastrOption
            );

            return;
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        } else if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    };

    const handleSendVerificationEmail = async () => {
        try {
            setIsLoading(true);
            const firebaseUser = await signInWithEmailAndPassword(getAuth(), email, password);
            await sendEmailVerification(firebaseUser.user);
            toast.success("Email verification link has been sent to your email", config.toastrOption);
            setIsLoading(false);
            setSendVerificationEmail(false);
            return;
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            if (error.message === "Firebase: Error (auth/wrong-password).")
                return toast.error("Wrong password", config.toastrOption);
            if (error.message === "Firebase: Error (auth/user-not-found).")
                return toast.error("User not found", config.toastrOption);
            if (error.message === "Firebase: Error (auth/too-many-requests).")
                return toast.error("Too many requests for this account, please try again later !", config.toastrOption);
            if (error.message === "Email not verified")
                return toast.error("Email has not been verified, please verify then try again !", config.toastrOption);
            if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                return toast.error("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", config.toastrOption);

            toast.error("Something went wrong", config.toastrOption);
        }
    }

    React.useEffect(() => {
        if (state.isLoggedIn) {
            setRedirect(true);
        }
    }, [state.isLoggedIn]);

    return (
        <div className="container-fluid pt-4">
            <ToastContainer />
            {redirect ? <Navigate to="/batches" /> : null}

            <div className="row mt-4">
                <div className="col-lg-6 col-md-6 offset-md-3 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3>Welcome back to Skills Stack</h3>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit} autoComplete={"false"}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        value={email}
                                        onChange={handleChange}
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        value={password}
                                        onChange={handleChange}
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                    />
                                </div>

                                {
                                    !sendVerificationEmail ? <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={isLoading}
                                    >
                                        Login {isLoading ? <Spin /> : null}
                                    </button> : <button
                                        type="button"
                                        className="btn btn-primary btn-block"
                                        disabled={isLoading}
                                        onClick={() => {
                                            handleSendVerificationEmail();
                                        }}
                                    >Send Verification Mail {isLoading ? <Spin /> : null}</button>
                                }
                            </form>
                        </div>

                        <div className="card-footer">
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}
