import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import axios from 'axios';
import React from 'react'
import config from '../../utils/config';

export default function Coupons({ state, setState, setSelectedCoupon }) {

    const [loading, setLoading] = React.useState(false);
    const [coupons, setCoupons] = React.useState([]);

    const loadData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}coupons`, {
                headers: {
                    Authorization: `Bearer ${state.token}`
                }
            })
            setCoupons(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadData();
    }, [])

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Coupon Name',
            dataIndex: 'name',
        },
        {
            title: 'Coupon Code',
            dataIndex: 'code',
        },
        {
            title: 'Discount %',
            dataIndex: 'discount_percentage',
        },
        {
            title: 'Max Discount Amount',
            dataIndex: 'max_discount_amount',
        },
        {
            title: "Expiry Date",
            render: (text, record) => {
                return record.expiry ? new Date(record.expiry).toDateString() : ''
            }
        },
        {
            title: "Is One Time",
            dataIndex: 'is_one_time',
            render: (text, record) => {
                return !record.is_one_time ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
            }
        },
        {
            title: "Is Flat Discount",
            dataIndex: 'is_flat_discount',
            render: (text, record) => {
                return !record.is_flat_discount ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
            }
        },
        {
            title: "Is Active",
            dataIndex: 'is_active',
            render: (text, record) => {
                return !record.is_active ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
            }
        },
        {
            title: "Edit",
            render: (text, record) => {
                return <Button type="primary" shape="round" icon={<EditOutlined />}
                    onClick={() => {
                        setSelectedCoupon(record)
                        setState({ ...state, showAddCoupon: true })
                    }} />
            }
        },
        {
            title: "Delete",
            render: (text, record) => {
                return <Button type="primary" danger shape="round" icon={<DeleteOutlined />}
                    onClick={() => {
                        setSelectedCoupon(record)
                        setState({ ...state, showDeleteCoupon: true })
                    }} />
            }
        }
    ]

    return (
        <div>
            <Table loading={loading} columns={columns} dataSource={coupons} rowKey="_id" />
        </div>
    )
}
