import { DeleteOutlined, EyeInvisibleFilled, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Input, Modal, Row, Spin, Table, Tag } from 'antd'
import axios from 'axios'
import React from 'react'
import config from '../../utils/config';

export default function Messages({ state }) {

    const [requests, setRequests] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedRequest, setSelectedRequest] = React.useState(null);
    const [comment, setComment] = React.useState("");

    const loadData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}landing/messages`, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })

            console.log(response.data.response.data)
            setRequests(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadData();
    }, [])

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
        },
        {
            title: 'Student Email',
            dataIndex: 'email',
        },
        {
            title: 'Student Mobile',
            dataIndex: 'phone',
        },
        {
            title: "Message",
            dataIndex: "message",
        },
        {
            title: "Completed",
            render: (text, record) => {
                return record.is_completed ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
            }
        },
        {
            title: "Created At",
            render: (text, record) => {
                return new Date(record.created_at).toLocaleString()
            }
        },
        {
            title: "Action",
            render: (text, record) => {
                return (
                    <Button onClick={() => {
                        setSelectedRequest(record);
                    }} type='dashed' icon={<EyeOutlined style={{
                        color: '#1890ff',
                    }} />} />
                )
            }
        }
    ]

    return (
        <>
            <Table pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
            }} rowKey="_id" loading={loading} columns={columns} dataSource={requests} />
            <Modal onCancel={() => {
                setSelectedRequest(null);
            }} title={"Take Action"} visible={selectedRequest !== null}>
                {
                    selectedRequest && (
                        <>
                            Request Status: {selectedRequest.is_completed ? "Completed" : "Pending"}

                            <br />
                            <br />
                            <Spin spinning={loading}> </Spin>

                            <Row>
                                <Col span={18}>
                                    <Input
                                        value={comment}
                                        placeholder="Comment"
                                        onChange={val => {
                                            setComment(val.target.value);
                                        }} />
                                </Col>
                                <Col span={5} offset={1}> {
                                    <Button style={selectedRequest.is_completed ? {
                                        backgroundColor: '#8710d8',
                                        color: 'white'
                                    } : {
                                        backgroundColor: '#1890ff',
                                        color: '#fff',
                                    }} onClick={() => {
                                        setLoading(true);
                                        axios.put(`${config.baseUrl}landing/message/${selectedRequest._id}`, {
                                            comment: comment,
                                            is_completed: !selectedRequest.is_completed
                                        }, {
                                            headers: {
                                                'Authorization': `Bearer ${state.token}`
                                            }
                                        }).then((resp) => {
                                            loadData();
                                            setComment("");
                                        }).catch(err => {
                                            console.log(err)
                                        }).finally(() => {
                                            setLoading(false);
                                            setSelectedRequest(null);
                                        })
                                    }}
                                    >{selectedRequest.is_completed ? "Re Open" : "Complete"}</Button>
                                } </Col>
                            </Row>

                            <br />
                            {
                                selectedRequest.comments.map((comment, index) => {
                                    return <Card style={{
                                        marginTop: 10
                                    }}>
                                        <Row>
                                            <Col style={{
                                                fontWeight: 'bold'
                                            }} span={6}>
                                                Comment
                                            </Col>
                                            <Col span={18}>
                                                {comment.comment}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{
                                                fontWeight: 'bold'
                                            }} span={6}>
                                                Time
                                            </Col>
                                            <Col span={18}>
                                                {new Date(comment.created_at).toLocaleString()}
                                            </Col>
                                        </Row>
                                    </Card>
                                })
                            }



                        </>
                    )
                }
            </Modal>
        </>
    )
}
