import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";

import { firebaseConfig } from "./utils/config";

initializeApp(firebaseConfig);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

reportWebVitals();
