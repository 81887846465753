import axios from 'axios';
import React from 'react'
import { Routes, Route } from "react-router-dom";
import Context from '../../context';
import BatchScreen from '../../screens/BatchScreen/BatchScreen';
import CouponScreen from '../../screens/CouponScreen/CouponScreen';
import EnrollmentRequestScreen from '../../screens/EnrollmentRequestsScreen/EnrollmentRequestScreen';
import LandingScreen from '../../screens/LandingScreen/LandingScreen';
import LoginScreen from '../../screens/LoginScreen/LoginScreen';
import MessageScreen from '../../screens/MessageScreen/MessageScreen';
import SessionScreen from '../../screens/SessionScreen/SessionScreen';
import UserScreen from '../../screens/UserScreen/UserScreen';
import VideoCourseScreen from '../../screens/VideoCourseScreen/VideoCourseScreen';
import config, { localStorageKeys } from '../../utils/config';
import Batch from '../Batches/Batch/Batch';

export default function Main() {

    const { state, setState } = React.useContext(Context);
    const [isLoading, setIsLoading] = React.useState(true);

    const checkLogin = async () => {
        const user = localStorage.getItem(localStorageKeys.userDetails);
        if (user) {
            try {
                const userDetails = JSON.parse(user);
                if (userDetails.sessionStartTime > 0) {
                    setIsLoading(true)
                    const resp = await axios.get(`${config.baseUrl}users/getUser`, {
                        headers: {
                            "Authorization": `Bearer ${userDetails.token}`
                        }
                    });
                    if (resp.data.response.status === 200) {
                        setState({
                            ...state,
                            isLoggedIn: true,
                            user: resp.data.response.data.user,
                            isGuest: false,
                            token: resp.data.response.data.token,
                        });
                    } else {
                    }
                    setIsLoading(false)
                } else {
                    console.log("Session has been expired");
                }
            } catch (error) {
                // console.log(error);
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        checkLogin();
        return () => {
            return null;
        };
    }, [])


    return (
        <>
            <Routes>


                {
                    state.isLoggedIn ? <>
                        <Route path="/" element={<LandingScreen />} />
                        <Route path="/batches" element={<BatchScreen />} />
                        <Route path="/batches/:id" element={<Batch />} />
                        <Route path="/users" element={<UserScreen />} />
                        <Route path="/enrollments" element={<EnrollmentRequestScreen />} />
                        <Route path="/sessions/:id" element={<SessionScreen />} />
                        <Route path="/messages" element={<MessageScreen />} />
                        <Route path="/videos" element={<VideoCourseScreen />} />

                        <Route path="/coupons" element={<CouponScreen />} />
                    </> :
                        <>

                            <Route path="/login" element={<LoginScreen />} />
                        </>
                }
                <Route path="/*" element={<LoginScreen />} />
            </Routes>
        </>
    );
}
