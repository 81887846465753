import React from 'react'
import { useParams } from 'react-router';

import { Button, Col, Drawer, PageHeader, Row, Table } from 'antd';
import axios from 'axios';
import Navigation from '../../Navigation/Navigation';
import Context from '../../../context';
import config from '../../../utils/config';
import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';

const BatchPage = () => {
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [batch, setBatch] = React.useState(null);
    const { state, useState } = React.useContext(Context);

    const loadBatch = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}groups/${params.id}`, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })
            setBatch(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadBatch();
    }, [params.id])

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: 'name'
        },
        {
            title: "Email",
            dataIndex: 'email'
        },
        {
            title: "Phone",
            dataIndex: 'mobile'
        },
        {
            title: "Action",
            render: (text, record) => {
                return <Button type="primary" icon={<DeleteFilled />} onClick={() => {
                    setDrawer(true);
                }}></Button>
            }
        }
    ];

    return <>
        <Drawer
            title={"Add Member: " + batch?.name}
            placement="right"
            closable={false}
            width={720}
            visible={Boolean(drawer)}
            onClose={() => setDrawer(false)}
            getContainer={false}
        >
            <div>
                Please Contact Your Developer, because he did not get time to implement this.
            </div>
        </Drawer>
        <Row>
            <Col span={24} className="category__header" >
                <PageHeader
                    className='site-page-header-responsive'
                    backIcon={false}
                    title={"Batch : " + batch?.name + " - " + batch?.course.name}
                    extra={
                        <>
                            <Button type="primary" onClick={() => {
                                // setSelectedBatch(null);
                                setDrawer(true);
                            }} shape="round" icon={<PlusCircleOutlined />} >
                                Add Student
                            </Button>
                        </>
                    }
                />
            </Col>
            {
                batch?.members?.length > 0 && <Col span={24} >
                    <Table pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                    }} columns={columns} dataSource={batch ? batch.members : []} loading={loading} bordered={true} />
                </Col>
            }
        </Row>
    </>
}

export default function Batch() {



    return (
        <Navigation>
            <BatchPage />
        </Navigation>
    )
}
