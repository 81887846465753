import React from 'react'
import { Row, Col, Button, Form, Input, Select, Spin, Checkbox } from 'antd';
import axios from 'axios';
import config from '../../utils/config';
import { ToastContainer, toast } from "react-toastify";

export default function AddCoupon({ state, setState, coupon, edit }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        form.resetFields();
        if (edit && coupon) {
            form.setFieldsValue({
                ...coupon
            })
        }
    }, [coupon,]);

    const handleSubmit = async (values) => {
        setLoading(true);
        if (edit) {
            try {
                const response = await axios.put(`${config.baseUrl}coupons/${coupon._id}`, values);
                toast.success("Coupon updated successfully", config.toastrOption);
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const response = await axios.post(`${config.baseUrl}coupons`, values, {
                    headers: {
                        Authorization: `Bearer ${state.token}`
                    }
                });
                toast.success("Added Coupon successfully", config.toastrOption);
            } catch (err) {
                console.log(err);
            }
        }
        setLoading(false);
    };


    return (
        <div>
            <ToastContainer />

            {
                loading ? <Spin /> : <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    size="large"

                >
                    <Row>
                        <Col span={11} >
                            <Form.Item name="name" label="Name" rules={[{ required: true }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2} >
                            <Form.Item name="code" label="Coupon Code" rules={[{ required: false }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={11} >
                            <Form.Item name="is_one_time" label="One Time Use ?" rules={[{ required: true }]} >
                                <Select>
                                    <Select.Option value={true}>Yes</Select.Option>
                                    <Select.Option value={false}>No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2} >
                            <Form.Item name="is_flat_discount" label="Is Flat Discount ?" rules={[{ required: true }]} >
                                <Select>
                                    <Select.Option value={true}>Yes</Select.Option>
                                    <Select.Option value={false}>No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11} >
                            <Form.Item name="is_active" label="Is Active ?" rules={[{ required: true }]} >
                                <Select>
                                    <Select.Option value={true}>Yes</Select.Option>
                                    <Select.Option value={false}>No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11} offset={2}>
                            <Form.Item name="expiry" label="Expiration Date" rules={[{ required: true }]} >
                                <Input type={"date"} />
                            </Form.Item>
                        </Col>

                        <Col span={11} >
                            <Form.Item name="discount_percentage" label="Discount Percentage" rules={[{ required: true }]} >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2} >
                            <Form.Item name="max_discount_amount" label="Max Discount" rules={[{ required: true }]} >
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" >
                                {
                                    edit ? "Update Coupon" : "Add Coupon"
                                }
                            </Button>
                        </Form.Item>

                        <Col span={24}>
                            <pre>
                                {JSON.stringify(form)}
                            </pre>
                        </Col>
                    </Row>
                </Form>
            }

        </div>
    )
}
