import { EyeOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, PageHeader, Row, Table } from 'antd';
import axios from 'axios';
import React from 'react'
import { useParams } from 'react-router'
import AddSession from '../../components/AddSession/AddSession';
import Navigation from '../../components/Navigation/Navigation';
import config from '../../utils/config';

function SessionPage() {

    const params = useParams();
    const [sessions, setSessions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [selectedSession, setSelectedSession] = React.useState(null);

    const loadSessions = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}groups/${params.id}/session`)
            setSessions(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadSessions(params.id);
    }, [params])

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_datetime',
            render: (text, record) => {
                return record.start_datetime ? record.start_datetime.split("T")[0] + " " + record.start_datetime.split("T")[1].substring(0, 8) : ''
            }
        },
        {
            title: "Duration",
            dataIndex: 'duration',
            render: (text, record) => {
                return record.duration + " minutes"
            }
        },
        {
            title: "Instructor",
            render: (text, record) => {
                return record.instructor.name
            }
        },
        {
            title: "View/Edit",
            render: (text, record) => {
                return <Button type="primary" shape="round" onClick={() => {
                    setSelectedSession(record);
                    setDrawer(true);
                }}
                    icon={<EyeOutlined />}
                />
            }
        }
    ]

    return (
        <>
            <Drawer
                title={"Edit Session"}
                placement="right"
                closable={false}
                width={720}
                visible={Boolean(drawer)}
                onClose={() => setDrawer(false)}
                getContainer={false}
            >
                <AddSession edit={true} session={selectedSession} />
            </Drawer>
            <Row>
                <Col span={24} className="category__header" >
                    <PageHeader
                        className='site-page-header-responsive'
                        backIcon={false}
                        title={"Sessions for Batch"}
                    />
                </Col>
                <Col span={24} >
                    <Table pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                    }} columns={columns} dataSource={sessions} loading={loading} bordered={true} />
                </Col>
            </Row>
        </>

    )
}

export default function SessionScreen() {
    return (
        <Navigation>
            <SessionPage />
        </Navigation>
    )
}