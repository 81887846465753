import { Col, PageHeader, Row } from 'antd';
import React from 'react'
import EnrollmentRequests from '../../components/EnrollmentRequests/EnrollmentRequests';
import Messages from '../../components/Messages/Messages';
import Navigation from '../../components/Navigation/Navigation'
import Users from '../../components/Users/Users';
import Context from '../../context';

const MessagePage = () => {
    const { state, setState } = React.useContext(Context);
    const [drawer, setDrawer] = React.useState(false);
    const [selectedRequest, setSelectedRequest] = React.useState(null);

    const handleSelectedRequest = (req) => {
        setSelectedRequest(req);
    }

    return (
        <Row>
            <Col span={24} className="category__header" >
                <PageHeader
                    className='site-page-header-responsive'
                    backIcon={false}
                    title="Landing Page Messages"
                />
            </Col>
            <Col span={24} >
                <Messages state={state} setSelectedBatch={() => {
                    handleSelectedRequest(null);
                }} />
            </Col>
        </Row>
    )
}


export default function MessageScreen() {
  return (
    <Navigation keyVal={'4'}>
        <MessagePage />
    </Navigation>
  )
}
