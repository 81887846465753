import { Button, Col, Drawer, PageHeader, Row } from 'antd';
import React from 'react'

import Navigation from '../../components/Navigation/Navigation';
import Context from '../../context';
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import AddSession from '../../components/AddSession/AddSession';
import Batches from '../../components/Batches/Batches';

const BatchPage = () => {
    const { state, setState } = React.useContext(Context);
    const [drawer, setDrawer] = React.useState(false);
    const [selectedBatch, setSelectedBatch] = React.useState(null);

    React.useEffect(() => {
    }, [])

    const handleSelectedbatch = (batch) => {
        setSelectedBatch(batch);
        setDrawer(true);
    }

    return (
        <div>
            <Drawer
                title={selectedBatch ? "Add Session: " + selectedBatch.name : "Add Demo Session"}
                placement="right"
                closable={false}
                width={720}
                visible={Boolean(drawer)}
                onClose={() => setDrawer(false)}
                getContainer={false}
            >
                <AddSession state={state} setState={setState} batch={selectedBatch} />
            </Drawer>

            <Row>
                <Col span={24} className="category__header" >
                    <PageHeader
                        className='site-page-header-responsive'
                        backIcon={false}
                        title="Batches"
                        extra={
                            <>
                                <Button type="primary" onClick={() => {
                                    setSelectedBatch(null);
                                    setDrawer(true);
                                }} shape="round" icon={<PlusCircleOutlined />} >
                                    Add Demo Session
                                </Button>
                            </>
                        }
                    />
                </Col>
                <Col span={24} >
                    <Batches setSelectedBatch={handleSelectedbatch} state={state} setState={setState} />
                </Col>
            </Row>
        </div>
    )
}

export default function BatchScreen() {
    return <Navigation keyVal={'1'} >
        <BatchPage />
    </Navigation>
}
