import { DeleteOutlined, EyeInvisibleFilled, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Spin, Switch, Table, Tag, Input } from 'antd'
import { Header } from 'antd/lib/layout/layout';
import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom';
import { user } from '../../context/initialState';
import config from '../../utils/config';

const { } = Input;

export default function Users({ state }) {

    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [firebaseData, setFirebaseData] = React.useState(null);
    const [showUnVerified, setShowUnVerified] = React.useState(false);
    const [showData, setShowData] = React.useState([]);

    const loadData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}users/getAllUsers`, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })
            setUsers(response.data.response.data)
            setShowData(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    const verifyMobile = async (user, value) => {
        try {
            setLoading(true);
            const response = await axios.post(`${config.baseUrl}users/updateByAdmin`, {
                email: user.email,
                data: {
                    is_mobile_verified: value
                }
            }, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })
            loadData();
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Mobile',
            render: (text, record, index) =>
                <> <a target={"_blank"} href={'https://wa.me/' + record.mobile}><span>{record.mobile}
                </span> </a>  &nbsp;&nbsp;&nbsp;
                    <Switch checked={record.is_mobile_verified} loading={loading} defaultChecked={false} onChange={(e) => {
                        if (e) {
                            verifyMobile(record, true)
                        } else {
                            verifyMobile(record, false)
                        }
                    }} /> </>
            ,
        },
        {
            title: "Is Active",
            dataIndex: 'is_active',
            render: (text, record) => {
                return record.is_active ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
            }
        },
        {
            title: "Created At",
            dataIndex: 'create_date',
            render: (text, record) => {
                return new Date(record.create_date).toLocaleString()
            }
        },
        {
            title: "Last Login",
            dataIndex: 'last_login',
            render: (text, record) => {
                return new Date(record.last_login).toLocaleString()
            }
        },
        {
            title: "Action",
            render: (text, record) => {
                return (
                    <div>
                        <Button onClick={() => {
                            setSelectedUser(record);
                        }} type="primary" icon={<EyeOutlined />} />
                    </div>
                )
            }
        }
    ]

    React.useEffect(() => {
        loadData();
    }, [])



    return (
        <>
            <Row style={{
                marginBottom: '16px'
            }}>
                <Col style={{
                    width: '100%',
                }} span={6}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(users.filter(user => user.name.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(users)
                        }
                    }} placeholder='Enter Name to Search' />
                </Col>
                <Col span={6} offset={2}>
                    <Input onChange={
                        (e) => {
                            const value = e.target.value;
                            if (value) {
                                setShowData(users.filter(user => user.email.toLowerCase().includes(value.toLowerCase())))
                            } else {
                                setShowData(users)
                            }
                        }
                    } placeholder='Enter Email to Search' />
                </Col>
                <Col span={6} offset={2}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(users.filter(user => user.mobile && user.mobile.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(users)
                        }
                    }} placeholder='Enter Mobile to Search' />
                </Col>
                <Col span={1} offset={1}>
                    <Button>Reset</Button>
                </Col>
            </Row>

            <Table loading={loading} columns={columns} dataSource={[...showData]} rowKey="_id" />

            {
                selectedUser && (
                    <Modal title="User Details" visible={true} onCancel={() => {
                        setSelectedUser(null);
                        setFirebaseData(null);
                    }} footer={null}>
                        <Spin spinning={loading}></Spin>
                        <>
                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={4}>Name</Col>
                                <Col span={20}>{selectedUser.name}</Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={4}>Email</Col>
                                <Col span={20}>{selectedUser.email} {firebaseData ? firebaseData.emailVerified ? <Tag color={"green"}>Yes</Tag> : <Tag color="red">No</Tag> : ""}</Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={4}>Mobile</Col>
                                <Col span={20}>{selectedUser.mobile} {selectedUser.is_mobile_verified ? <Tag color={"green"}>Yes</Tag> : <Tag color="red">No</Tag>}</Col>
                            </Row>



                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>Is Working</Col>
                                <Col span={18}>{selectedUser.is_currently_working ? <Tag color={"green"}>Yes</Tag> : <Tag color="red">No</Tag>} </Col>
                            </Row>

                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>Company</Col>
                                <Col span={18}>{selectedUser.current_company} </Col>
                            </Row>

                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>Designation</Col>
                                <Col span={18}>{selectedUser.current_position} </Col>
                            </Row>

                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>Is Student</Col>
                                <Col span={18}>{selectedUser.is_current_student ? <Tag color={"green"}>Yes</Tag> : <Tag color="red">No</Tag>} </Col>
                            </Row>

                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>College</Col>
                                <Col span={18}>{selectedUser.last_college} </Col>
                            </Row>

                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>Year</Col>
                                <Col span={18}>{selectedUser.last_degree_year} </Col>
                            </Row>

                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}>Degree</Col>
                                <Col span={18}>{selectedUser.highest_qualification} </Col>
                            </Row>


                            <Row style={{
                                marginTop: '10px'
                            }}>
                                <Col style={{
                                    fontWeight: 'bold',
                                }} span={6}></Col>
                                <Col span={18}> </Col>
                            </Row>


                            {
                                !firebaseData && <Button type="primary" onClick={() => {
                                    setLoading(true);
                                    axios.get(`${config.baseUrl}users/getUserFirebaseData/${selectedUser.email}`, {
                                        headers: {
                                            'Authorization': `Bearer ${state.token}`
                                        }
                                    }).then(resp => {
                                        setFirebaseData(resp.data.response.data);
                                        setLoading(false);
                                    }).catch(err => {
                                        setLoading(false);
                                    })
                                }}>
                                    Fetch Firebase Data
                                </Button>
                            }
                        </>
                    </Modal>
                )
            }
        </>

    )
}
