import React from 'react'
import { Row, Col, Button, Form, Input, Select, Spin } from 'antd';
import axios from 'axios';
import config from '../../utils/config';
import { ToastContainer, toast } from "react-toastify";
const { Option } = Select;

export default function AddSession({ state, setState, batch, edit, session }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [teachers, setTeachers] = React.useState([]);

    async function handleSubmit(data) {
        console.log(data);
        setLoading(true);
        if (edit) {
            try {
                const response = await axios.put(`${config.baseUrl}sessions/${session._id}`, data);
                setLoading(false);
                toast.success("Session updated successfully", config.toastrOption);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        } else {
            if (batch) {
                axios.post(`${config.baseUrl}groups/${batch._id}/session`, data)
                    .then(res => {
                        console.log(res);
                        setLoading(false);
                        toast.success("Added Session successfully", config.toastrOption);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    })
            } else {
                axios.post(`${config.baseUrl}sessions/demo`, data)
                    .then(res => {
                        console.log(res);
                        setLoading(false);
                        toast.success("Added Session successfully", config.toastrOption);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    })
            }
        }
    }

    const loadTeachers = async () => {
        try {
            const response = await axios.get(`${config.baseUrl}users/teachers`)
            setTeachers(response.data.response.data)
        } catch (err) {
            console.log(err)
        }
    }

    React.useEffect(() => {
        loadTeachers();
        form.resetFields();
        if (edit && session) {
            form.setFieldsValue({
                topic: session.topic,
                start_datetime: session.start_datetime,
                duration: session.duration,
                instructor: session.instructor._id,
                session_link: session.session_link,
                is_local_video: session.is_local_video,
                sessions_video_link: session.sessions_video_link
            })
        }
    }, [batch, session]);

    return (
        <div>
            <ToastContainer />
            {
                loading ? <Spin /> : <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    size="large"

                >
                    <Row>
                        <Col span={24} >
                            <Form.Item name="topic" label="Topic" rules={[{ required: true }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={16} >
                            <Form.Item name="start_datetime" label="Start Date Time" rules={[{ required: true }]} >
                                <Input type={"datetime-local"} />
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={2} >
                            <Form.Item name="duration" label="Duration (in Minutes)" rules={[{ required: true }]} >
                                <Input type={"number"} min={30} />
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Form.Item name="instructor" label="Instructor" rules={[{ required: true }]} >
                                <Select>
                                    {
                                        teachers.map(teacher => {
                                            return <Select.Option key={teacher._id} value={teacher._id}>{teacher.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Form.Item name="session_link" label="Session Link" rules={[{ required: true }]} >
                                <Input />
                            </Form.Item>
                        </Col>

                        {
                            edit && session && <Col span={24} >
                                <Form.Item name="is_local_video" label={"Is Local Video"} rules={[{ required: true }]} >
                                    <Select >
                                        <Select.Option value={true}>Yes</Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        }

                        {
                            edit && session && <Col span={24} >
                                <Form.Item name="sessions_video_link" label="Session Video" rules={[{ required: false }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                        }
                        <Form.Item>
                            <Button type="primary" htmlType="submit" >
                                {
                                    edit ? "Update Session" :
                                        batch ? "Add Session" : "Add Demo Session"
                                }
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            }
        </div>
    )
}
