import { DeleteOutlined, EyeInvisibleFilled, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Table, Tag } from 'antd'
import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom';
import config from '../../utils/config';

export default function Batches({ state, setState, setSelectedBatch }) {

    const [batches, setBatches] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}groups`)
            setBatches(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadData();
    }, [])

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Batch Name',
            dataIndex: 'name',
        },
        {
            title: "Is Group",
            dataIndex: 'is_one_student',
            render: (text, record) => {
                return !record.is_one_student ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
            }
        },
        {
            title: 'Members',
            render: (text, record) => record.members.length
        },
        {
            title: 'Details',
            render: (text, record) => <Link to={`/batches/${record._id}`}><Button type="primary" shape="round" icon={<EyeOutlined />} /></Link>
        },
        {
            title: "Start Date",
            dataIndex: 'course_start_date',
            render: (text, record) => {
                return record.course_start_date ? new Date(record.course_start_date).toDateString() : ''
            }
        },
        {
            title: "View Sessions",
            render: (text, record) => {
                return <Link to={`/sessions/${record._id}`}><Button type="primary" shape="round" icon={<EyeOutlined />} /></Link>
            }
        },
        {
            title: "Add Session",
            dataIndex: "_id",
            render: (text, record) => {
                return <Button type="primary" onClick={
                    () => {
                        setSelectedBatch(record);
                    }
                } shape="round" icon={<PlusCircleOutlined />} />
            }
        },
        {
            title: 'Delete',
            dataIndex: '_id',
            render: (id) => (
                <Button danger type="primary" shape="round" onClick={() => { }} icon={<DeleteOutlined />} />
            )
        }
    ]

    return (
        <Table loading={loading} columns={columns} dataSource={batches} rowKey="_id" />
    )
}
