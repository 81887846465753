import { DeleteOutlined, EyeInvisibleFilled, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Table, Row, Col, Input } from 'antd'
import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom';
import { user } from '../../context/initialState';
import config from '../../utils/config';

export default function EnrollmentRequests({ state }) {

    const [requests, setRequests] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showData, setShowData] = React.useState([]);

    const loadData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.baseUrl}enrollments`, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })
            console.log(response.data.response.data)
            setRequests(response.data.response.data)
            setShowData(response.data.response.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    const columns = [
        {
            title: 'Sr. No.',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Student Name',
            render: (text, record) => {
                return record.user.name
            }
        },
        {
            title: 'Student Email',
            render: (text, record) => {
                return record.user.email
            }
        },
        {
            title: 'Course Fee',
            render: (text, record) => {
                return record.course_total_fee
            }
        },
        {
            title: 'Course Paid',
            render: (text, record) => {
                return record.course_total_fee_paid
            }
        },
        {
            title: 'Course Name',
            render: (text, record) => {
                return record.course.name
            }
        },
        {
            title: 'Create Date',
            render: (text, record) => {
                return new Date(record.create_date).toLocaleString()
            }
        }
    ]

    React.useEffect(() => {
        loadData();
    }, [])

    return (
        <>
            <Row style={{
                marginBottom: '16px'
            }}>
                <Col span={4}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(requests.filter(req => req.user.name.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(requests)
                        }
                    }} placeholder='Enter Name to Search' />
                </Col>
                <Col span={4} offset={1}>
                    <Input onChange={
                        (e) => {
                            const value = e.target.value;
                            if (value) {
                                setShowData(requests.filter(req => req.user.email.toLowerCase().includes(value.toLowerCase())))
                            } else {
                                setShowData(requests)
                            }
                        }
                    } placeholder='Enter Email to Search' />
                </Col>
                <Col span={4} offset={1}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(requests.filter(req => req.user.mobile && req.user.mobile.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(requests)
                        }
                    }} placeholder='Enter Mobile to Search' />
                </Col>
                <Col span={4} offset={1}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(requests.filter(req => req.course.name && req.course.name.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(requests)
                        }
                    }} placeholder='Enter Request to Search' />
                </Col>
                <Col span={1} offset={1}>
                    <Button>Reset</Button>
                </Col>
            </Row>

            <Table pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
            }} loading={loading} columns={columns} dataSource={showData} rowKey="_id" />

        </>
    )
}
