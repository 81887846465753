import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, PageHeader, Row } from 'antd';
import React from 'react'
import Navigation from '../../components/Navigation/Navigation';
import AddVideoCourse from '../../components/VideoCourses/AddVideoCourse';
import VideoCourses from '../../components/VideoCourses/VideoCourses';
import Context from '../../context';

const VideoCoursePage = () => {
    const { state, setState } = React.useContext(Context);
    const [drawer, setDrawer] = React.useState(false);

    React.useEffect(() => {
        console.log(state)
    }, [])

    return (

        <>
            <Drawer
                title={"Add Video Course"}
                placement="right"
                closable={false}
                width={720}
                visible={Boolean(drawer)}
                onClose={() => setDrawer(false)}
                getContainer={false}
            >
                {/* <AddSession state={state} setState={setState} batch={selectedBatch} /> */}
                <AddVideoCourse state={state} setState={setState} />
            </Drawer>


            <Row>

                <Col span={24} className="category__header" >
                    <PageHeader
                        className='site-page-header-responsive'
                        backIcon={false}
                        title="Video Courses"
                        extra={
                            <Button style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }} type="primary" onClick={() => {
                                // setSelectedBatch(null);
                                setDrawer(true);
                            }} shape="round" icon={<PlusCircleOutlined />} >
                                Add Video Course
                            </Button>
                        }
                    />
                </Col>
                <Col span={24} >
                    {/* <Users state={state}  /> */}
                    <VideoCourses state={state} setState={setState} />
                </Col>
            </Row>
        </>
    )
}


export default function VideoCourseScreen() {
    return (
        <Navigation keyVal={'5'}>
            <VideoCoursePage />
        </Navigation>
    )
}
