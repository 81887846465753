import { Button, Col, PageHeader, Row } from 'antd';
import React from 'react'
import Batches from '../../components/Batches/Batches';

import Navigation from '../../components/Navigation/Navigation';
import Users from '../../components/Users/Users';
import Context from '../../context';

const UserPage = () => {
    const { state, setState } = React.useContext(Context);
    const [drawer, setDrawer] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(null);

    React.useEffect(() => {
        console.log(state)
    }, [])

    const handleSelectedUser = (user) => {
        setSelectedUser(user);
    }

    return (
        <Row>
            <Col span={24} className="category__header" >
                <PageHeader
                    className='site-page-header-responsive'
                    backIcon={false}
                    title="Users"

                />
            </Col>
            <Col span={24} >
                <Users state={state} setSelectedBatch={() => {
                    handleSelectedUser(null);
                }} />
            </Col>
        </Row>
    )
}

export default function UserScreen() {
    return <Navigation keyVal={'2'}>
        <UserPage />
    </Navigation>
}
