import { DeleteOutlined, EyeInvisibleFilled, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Spin, Switch, Table, Tag, Input, PageHeader } from 'antd'
import { Header } from 'antd/lib/layout/layout';
import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom';
import { user } from '../../context/initialState';
import config from '../../utils/config';

export default function VideoCourses() {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showData, setShowData] = React.useState([]);

    const columns = [
        {
            title: "S.No",
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: "Name",
            dataIndex: "name",
        }
    ]

    const loadData = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${config.baseUrl}videos/courses`);
            setData(res.data.response.data);
            setShowData(res.data.response.data);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadData();
    }, [])

    return (
        <>
            <Row style={{
                marginBottom: '16px'
            }}>
                <Col style={{
                    width: '100%',
                }} span={6}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(data.filter(user => user.name.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(data)
                        }
                    }} placeholder='Enter Name to Search' />
                </Col>
                <Col span={6} offset={2}>
                    <Input onChange={
                        (e) => {
                            const value = e.target.value;
                            if (value) {
                                setShowData(data.filter(user => user.email.toLowerCase().includes(value.toLowerCase())))
                            } else {
                                setShowData(data)
                            }
                        }
                    } placeholder='Enter Email to Search' />
                </Col>
                <Col span={6} offset={2}>
                    <Input onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                            setShowData(data.filter(user => user.mobile && user.mobile.toLowerCase().includes(value.toLowerCase())))
                        } else {
                            setShowData(data)
                        }
                    }} placeholder='Enter Mobile to Search' />
                </Col>
                <Col span={1} offset={1}>
                    <Button>Reset</Button>
                </Col>
            </Row>

            <Table loading={loading} columns={columns} dataSource={[...showData]} rowKey="_id" />

        </>
    )
}
