import { BrowserRouter } from "react-router-dom";
import './App.css';
import 'antd/dist/antd.min.css'
import Main from "./components/Main/Main";
import Context from "./context";
import React from "react";
import initialState from "./context/initialState";
import "react-toastify/dist/ReactToastify.css";

function App() {
	const [state, setState] = React.useState(initialState);
	return (
		<BrowserRouter>
			<Context.Provider value={{ state, setState }}>
				<Main />
			</Context.Provider>
		</BrowserRouter>
	);
}

export default App;
