import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, PageHeader, Row } from 'antd';
import React from 'react'
import AddCoupon from '../../components/Coupons/AddCoupon';
import Coupons from '../../components/Coupons/Coupons';
import Navigation from '../../components/Navigation/Navigation'
import Context from '../../context';

export default function CouponScreen() {
    const { state, setState } = React.useContext(Context);
    const [drawer, setDrawer] = React.useState(false);
    const [selectedCoupon, setSelectedCoupon] = React.useState(null);

    const handleSelectedCoupon = (coupon) => {
        setSelectedCoupon(coupon);
        setDrawer(true);
    }

    return (
        <Navigation keyVal={"6"}>
            <div>
                <Drawer
                    title={selectedCoupon ? "Edit Coupon: " + selectedCoupon.name : "Add Coupon"}
                    placement="right"
                    closable={false}
                    width={620}
                    visible={drawer}
                    onClose={() => setDrawer(false)}
                    getContainer={false}
                >
                    <AddCoupon
                        state={state}
                        setState={setState}
                        coupon={selectedCoupon}
                        edit={selectedCoupon ? true : false} />
                </Drawer>

                <Row>
                    <Col span={24} className="category__header" >
                        <PageHeader
                            className='site-page-header-responsive'
                            backIcon={false}
                            title="Coupons"
                            extra={
                                <>
                                    <Button type="primary" onClick={() => {
                                        setSelectedCoupon(null);
                                        setDrawer(true);
                                    }} shape="round" icon={<PlusCircleOutlined />} >
                                        Add Coupon
                                    </Button>
                                </>
                            }
                        />
                    </Col>
                    <Col span={24} >
                        <Coupons setSelectedCoupon={handleSelectedCoupon} state={state} setState={setState} />
                    </Col>
                </Row>

            </div>
        </Navigation>
    )
}
