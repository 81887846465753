const { toast } = require("react-toastify");

const config = {
    baseUrl: process.env.NODE_ENV === 'production' ? "https://api.skillsstack.in/": "http://localhost:5000/",
    toastrOption: {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    },
    firebaseConfig: {
        apiKey: "AIzaSyChxSUUYKOj6NNqbLkwBYxvplL08bh0BgU",
        authDomain: "skill-stream.firebaseapp.com",
        projectId: "skill-stream",
        storageBucket: "skill-stream.appspot.com",
        messagingSenderId: "14613204222",
        appId: "1:14613204222:web:b8242f88e1b84f180ba9af",
        measurementId: "G-CJVHBSEGSG"
    },
    localStorageKeys: {
        userDetails: "skill-stream-offline-user"
    }
};

module.exports = config;