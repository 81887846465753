import React from 'react';
import { Layout, Menu } from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
    FileOutlined,
    TeamOutlined,
    UserOutlined,
    BookOutlined,
    MessageOutlined,
    VideoCameraOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import './Navigation.css';
import { Link } from 'react-router-dom';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation(props) {

    const [collapsed, setCollapsed] = React.useState(false);

    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={"220px"} collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <Link to={'/'}>
                    <div className="logo">
                        <img src={require("../../assets/images/logo-white-transparent.png")} alt="logo" className='logo-image' />
                    </div>
                </Link>
                <Menu theme="dark" selectedKeys={[props.keyVal]}  mode="inline">
                  
                    <Menu.Item key="1" icon={<PieChartOutlined />}>
                        <Link to={'/batches'}>
                            Batches
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<UserOutlined />}>
                        <Link to={'/users'} >
                            Users
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<BookOutlined />}>
                        <Link to={'/enrollments'}>
                            Enrollment Requests
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<MessageOutlined />}>
                        <Link to={'/messages'}>
                            Messages
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<VideoCameraOutlined />}>
                        <Link to={'/videos'}>
                            Videos
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<CopyOutlined />}>
                        <Link to={'/coupons'}>
                            Coupons
                        </Link>
                    </Menu.Item>
                    {/* <SubMenu key="sub1" icon={<UserOutlined />} title="User">
                        <Menu.Item key="3">Tom</Menu.Item>
                        <Menu.Item key="4">Bill</Menu.Item>
                        <Menu.Item key="5">Alex</Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
                        <Menu.Item key="6">Team 1</Menu.Item>
                        <Menu.Item key="8">Team 2</Menu.Item>
                    </SubMenu>
                    <Menu.Item key="9" icon={<FileOutlined />}>
                        Files
                    </Menu.Item> */}
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>
                    {
                        props.children
                    }
                </Content>
                <Footer style={{ textAlign: 'center' }}>Copyright © 2022 Skills Stack - All rights reserved</Footer>
            </Layout>
        </Layout>
    )
}
