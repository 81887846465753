const initialState = {
    isLoading: false,
    user: null,
    isLoggedIn: false,
    isSuperAdmin: false,
    token: "",
    currentScreen: "",
    events: [],
    selectedBatch: null,
}

module.exports = initialState;