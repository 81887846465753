import React from 'react'
import Navigation from '../../components/Navigation/Navigation';
import Context from '../../context';

function LandingPage() {
    const { state, setState } = React.useContext(Context);

    React.useEffect(() => {
        console.log(state)
    }, [])

    return (
        <div>LandingScreen</div>
    )
}

export default function LandingScreen() {
    return <Navigation >
        <LandingPage />
    </Navigation>
}