import React from "react";
import { render } from "react-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import config from "../../utils/config";

export default function AddVideoCourse({ state, setState }) {
  const [code, setCode] = React.useState(
    `{
      "name": "SQL for Beginners",
      "description": "Learn the basic of SQL",
      "course_details": ["detail1", "Detail2"],
      "highlights": [
        {
          "name": "duration",
          "value": "4 hours"
        },
        {
          "name": "Lectires",
          "value": "25"
        }
      ],
      "pre_requisites":["none"],
      "job_opportunities": ["DBA", "Full Stack Dev", "Backend Dev"],
      "languages_tools": [
        {
          "name": "",
          "image": "",
        },
        {
          "name": "",
          "image": ""
        }
      ],
      "companies": [
        {
          "name": "",
          "image": "",
        },
        {
          "name": "",
          "image": ""
        }
      ],
      "couse_price": 999,
      "offer_price": 299,
      "category": "Database"
}`
  );

  const addCourse = async () => {
    try {
      // return;

      const response = await axios.post(
        `${config.baseUrl}videos/`,
        {
          code: JSON.parse(code),
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      console.log(response.data.response.data);
      if (response.status === 200) {
        toast.success("Course Added Successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log(err.message)
      if (err.message.startsWith("Unexpected token")) {
        toast.error("Invalid JSON");
        return;
      }

      toast.error("Something went wrong");
    }
  }

  return (
    <>
      <ToastContainer />
      <AceEditor
        mode="javascript"
        theme="textmate"
        onChange={(e) => {
          setCode(e);
        }}
        value={code}
        style={{ width: "100%", height: "95%" }}
        fontSize={18}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
      />
      <Button onClick={addCourse} type="primary">Add Course</Button>
    </>
  )
}
